import React from "react";
import "./App.css";
import { Helmet } from "react-helmet";

function App() {
  const [sentence, setSentence] = React.useState("");
  const [discordText, setDiscordText] = React.useState("");
  const DISCORD_PREFIX = "regional_indicator_";

  React.useEffect(() => {
    setDiscordText(
      sentence
        .split("")
        .map((letter) => letterHandler(letter))
        .join(" ")
    );
  }, [sentence]);

  const letterHandler = (letter: string) => {
    if (letter === " ") {
      return " ";
    } else if (letter === "'") {
      return "'";
    } else if (isNumber(letter)) {
      return letter;
    } else if (hasSpecialCharacter(letter)) {
      return letter;
    } else if (letter === "o" || letter === "O") {
      const random = Math.floor(Math.random() * 2);
      return random === 0
        ? `:${DISCORD_PREFIX}${removeAccent(letter)}:`
        : ":o:";
    } else if (letter === "b" || letter === "B") {
      const random = Math.floor(Math.random() * 2);
      return random === 0
        ? `:${DISCORD_PREFIX}${removeAccent(letter)}:`
        : ":b:";
    } else {
      return `:${DISCORD_PREFIX}${removeAccent(letter)}:`;
    }
  };

  // function that check if a string contains a number
  function isNumber(myString: string) {
    return !isNaN(parseFloat(myString));
  }

  // function that check if the string contains a special character
  function hasSpecialCharacter(str: string) {
    var regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    return regex.test(str);
  }

  // function that remove accent from a string
  function removeAccent(str: string) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }

  return (
    <div className="App">
      <Helmet>
        <title>Discord Text Generator</title>
      </Helmet>

      <header className="App-header">
        <img
          src={
            "https://upload.wikimedia.org/wikipedia/fr/thumb/4/4f/Discord_Logo_sans_texte.svg/1200px-Discord_Logo_sans_texte.svg.png"
          }
          className="App-logo"
          alt="logo"
        />
        <h1>Discord Text Generator</h1>
        <br></br>
        <textarea
          style={{
            width: "50vw",
            height: "25vh",
            backgroundColor: "#fcfcfc",
            border: "none",
            borderRadius: "15px",
            padding: "10px",
          }}
          onChange={(e) => setSentence(e.target.value)}
        ></textarea>
        <br></br>
        <button
          style={{
            height: "5vh",
            paddingLeft: "100px",
            paddingRight: "100px",
            border: "none",
            color: "white",
            fontSize: "1vw",
            fontWeight: "bold",
            borderRadius: "50px",
            textTransform: "uppercase",
            cursor: "pointer",
            background:
              "linear-gradient(to right top, #003688, #1942a2, #2e4ebc, #425ad7, #5766f2)",
          }}
          onClick={() => {
            navigator.clipboard
              .writeText(discordText)
              .then(() => {
                alert("Copied to clipboard");
              })
              .catch(() => {
                alert("Error copying to clipboard");
              });
          }}
        >
          Copy
        </button>
      </header>
    </div>
  );
}

export default App;
