import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// @ts-ignore
import HttpsRedirect from "react-https-redirect";
// @ts-ignore
import DocumentMeta from "react-document-meta";

const meta = {
  title: "Discord Regional Text Generator",
  description: "Generate text for Discord with regional indicators",
  canonical: "http://example.com/path/to/page",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "react,meta,document,html,tags",
    },
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <DocumentMeta {...meta} />
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
